import { Add, Check, FilterAltOutlined, Search } from '@mui/icons-material';
import {
  CardContent,
  IconButton,
  OutlinedInput,
  List,
  ListItem,
  ListItemText,
  styled,
  Box,
  Card,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';

import { useEffect, useState, type Dispatch, type MouseEventHandler, type SetStateAction, type ChangeEvent } from 'react';
import { KwButton } from '../../kw-ui-components/KwButton/KwButton';
// eslint-disable-next-line import/extensions
import { PolicyProfileMode } from './enum/PolicyProfileMode.enum';
// eslint-disable-next-line import/extensions
import { PolicyTab } from './enum/PolicyTab.enum';
import type { PolicyAction, PolicyActionGroup, RuleEntity } from './getData';
import { PolicyActionAdminEmailModal } from './PolicyActionAdminEmailModal';
// import { PolicyProfilePlatformSelect } from './PolicyProfilePlatformSelect';

type PolicyCardData<T> = Array<T>;
interface CardContentProps {
  tab: string;
  data: PolicyCardData<PolicyActionGroup | RuleEntity>;
  selectedData: PolicyCardData<PolicyAction | RuleEntity>;
  addAction: Dispatch<SetStateAction<PolicyAction | RuleEntity>>;
  index: string;
  mode: string;
  setShowModal?: Dispatch<SetStateAction<{ visible: boolean; row: PolicyAction }>>;
  platform?: string;
  setPlatform?: Dispatch<SetStateAction<string>>;
}

interface ListButtonProps {
  isSelected: boolean;
  action: MouseEventHandler<HTMLButtonElement>;
}

interface ListRowProps {
  row: PolicyAction | RuleEntity;
}

interface ListCardProps {
  tab: string;
  setTab: Dispatch<SetStateAction<PolicyTab>>;
  rules: RuleEntity[];
  actions: PolicyActionGroup[];
  selectedRules: RuleEntity[];
  selectedActions: PolicyAction[];
  handleAddingRules: Dispatch<SetStateAction<RuleEntity>>;
  handleAddingActions: Dispatch<SetStateAction<PolicyAction>>;
  mode: string;
  modalOptions: { visible: boolean; row: PolicyAction; isEdit: boolean };
  setModalOptions: Dispatch<SetStateAction<{ visible: boolean; row: PolicyAction; isEdit: boolean }>>;
}

export const RuleNameAndCategoryBadge = ({ name, category }: { name: string; category: string }) => (
  <>
    {name}
    {/* <AppType category={category}>{category}</AppType> */}
  </>
);

const PolicyListCardContent = ({
  tab,
  data = [],
  selectedData = [],
  addAction,
  index,
  mode,
  setShowModal,
  platform,
  setPlatform,
}: CardContentProps) => {
  const [selectedItems, setSelectedItems] = useState(selectedData);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    // FIXME: This feels very hacky and doesn't seem like it's the right way to handle this
    // What was happening was there was infinite rerenders since React saw the selectedItems hook
    // changing each time, but the thing I'm not sure sure about is this hook should only
    // be called when "selectedData" changes, which it doesn't.
    if (selectedData.length !== selectedItems.length) {
      setSelectedItems(selectedData);
    }
  }, [selectedData, selectedItems.length]);

  const handleButtonClick = (row: PolicyAction | RuleEntity) => {
    if (selectedItems.map(({ id }) => id).includes(row.id)) {
      setSelectedItems(selectedItems.filter(item => item.id !== row.id));
    } else {
      setSelectedItems(prevSelectedItems => [...prevSelectedItems, row]);
    }
    if ('shouldShowModal' in row && row.shouldShowModal) {
      setShowModal({ visible: true, row });
    } else {
      addAction(row);
    }
  };

  function getItemVal(item: PolicyAction | RuleEntity, property: string): string {
    return item[property];
  }

  const ListButton = ({ isSelected, action }: ListButtonProps) => {
    return isSelected ? (
      <KwButton startIcon={<Check />} disabled>
        Added
      </KwButton>
    ) : (
      <KwButton variant="basic" startIcon={<Add />} onClick={action} disabled={mode === PolicyProfileMode.VIEW} data-testid="add-button">
        Add
      </KwButton>
    );
  };

  const ListRow = ({ row }: ListRowProps) => {
    const isSelected = selectedItems.map(({ id }) => id).includes(row.id);
    const category = 'skuSetting' in row && row.skuSetting === 'app-rules' ? 'App' : 'Device';

    return (
      <>
        <ListItem
          key={row.id}
          secondaryAction={<ListButton isSelected={isSelected} action={() => handleButtonClick(row)} />}
          divider={true}
          alignItems="flex-start"
        >
          <ListItemText
            primary={<RuleNameAndCategoryBadge name={row.name} category={category} />}
            secondary={row.description}
            // eslint-disable-next-line react/forbid-component-props
            style={{ marginRight: '70px' }}
          />
        </ListItem>
      </>
    );
  };

  const filterData =
    searchText.length > 0
      ? data.filter(
          (item: RuleEntity) =>
            item.name.toLowerCase().includes(searchText.toLowerCase()) || item.description.toLowerCase().includes(searchText.toLowerCase()),
        )
      : data;

  return (
    tab === index && (
      <CardContent>
        {index === PolicyTab.RULES && (
          <>
            {/* <PolicyProfilePlatformSelect platform={platform} setPlatform={setPlatform} /> */}
            {/* {platform.length ? ( */}
            <StyledToolHeader>
              <IconButton>
                <FilterAltOutlined />
              </IconButton>

              <StyledSearchBar placeholder="Search" startAdornment={<Search />} onChange={e => setSearchText(e.target.value)} />
            </StyledToolHeader>
            {/* ) : null} */}
          </>
        )}
        <StyledList>
          {index === PolicyTab.ACTIONS ? (
            <div>
              {data.map((group: PolicyActionGroup) => (
                <StyledSection key={group.groupName}>
                  <Typography variant="h5Medium">{group.groupName}</Typography>
                  {group.actions.map((row: PolicyAction) => (
                    <ListRow row={row} key={getItemVal(row, 'id')} />
                  ))}
                </StyledSection>
              ))}
            </div>
          ) : (
            <div>
              <StyledSection>
                {filterData.map((row: RuleEntity) => (
                  <ListRow row={row} key={getItemVal(row, 'id')} />
                ))}
              </StyledSection>
            </div>
          )}
        </StyledList>
      </CardContent>
    )
  );
};

export const PolicyListCard = ({
  tab,
  setTab,
  rules,
  actions,
  selectedRules,
  selectedActions,
  handleAddingRules,
  handleAddingActions,
  mode,
  modalOptions,
  setModalOptions,
}: ListCardProps) => {
  // FIX ME: uncomment when platform filter is ready to be implemented
  // const [platform, setPlatform] = useState('');
  // const [filteredRules, setFilteredRules] = useState([]);

  // useEffect(() => {
  //   if (platform.length) {
  //     setFilteredRules(rules.filter(rule => rule.platform === platform || rule.platform === 'all'));
  //   }
  // }, [platform, rules]);

  const handleTabChange = (_event: ChangeEvent<HTMLButtonElement>, newValue: PolicyTab) => {
    setTab(newValue);
  };

  const setCurrentEmails = (emails: string[]) => {
    const updatedRow = { ...modalOptions.row };
    updatedRow.emails = emails;
    handleAddingActions(updatedRow);
    setModalOptions({ row: updatedRow, visible: false, isEdit: false });
  };
  const emails = modalOptions.row?.emails ?? [];
  return (
    <>
      {' '}
      {modalOptions.visible && (
        <PolicyActionAdminEmailModal
          onClose={() => setModalOptions({ ...modalOptions, visible: false, isEdit: false })}
          currentEmails={emails}
          setCurrentEmails={setCurrentEmails}
          isEdit={modalOptions.isEdit}
        />
      )}
      <StyledCard>
        <TabHeader>
          <Tabs value={tab} onChange={handleTabChange} variant="fullWidth">
            <StyledTab label={<Typography variant="h5Medium">Rules</Typography>} value={PolicyTab.RULES} />
            <StyledTab label={<Typography variant="h5Medium">Actions</Typography>} value={PolicyTab.ACTIONS} />
          </Tabs>
        </TabHeader>
        <PolicyListCardContent
          tab={tab}
          data={rules}
          // data={filteredRules} // FIX ME: uncomment when platform filter is ready to be implemented
          selectedData={selectedRules}
          addAction={handleAddingRules}
          index={PolicyTab.RULES}
          mode={mode}
          // platform={platform} // FIX ME: uncomment when platform filter is ready to be implemented
          // setPlatform={setPlatform} // FIX ME: uncomment when platform filter is ready to be implemented
        />
        <PolicyListCardContent
          tab={tab}
          data={actions}
          selectedData={selectedActions}
          addAction={handleAddingActions}
          index={PolicyTab.ACTIONS}
          mode={mode}
          setShowModal={setModalOptions}
        />
      </StyledCard>
    </>
  );
};

const StyledList = styled(List)({
  maxHeight: '600px',
  overflow: 'auto',
});

const StyledSection = styled('div')({
  marginTop: '24px',
  marginBottom: '24px',
});

const StyledSearchBar = styled(OutlinedInput)({
  width: '90%',
  height: '40px',
  backgroundColor: 'white',
});

const StyledCard = styled(Card)({
  width: '100%',
  height: 771,
  backgroundColor: 'rgba(79, 134, 142, 0.1)',
  padding: '2% 1%',
  borderRadius: '8px',
});

const TabHeader = styled(Box)({ borderBottom: '1px solid #D8D8D8' });

const StyledTab = styled(Tab)({
  textTransform: 'none',
});

const StyledToolHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const AppType = styled('div')<{ category?: string }>`
  display: inline-block;
  padding: 4px 16px;
  margin-left: 8px;
  border-radius: 16px;
  flex-shrink: 0;
  text-align: center;
  background-color: ${({ category }) => (category === 'App' ? '#FFA38B' : '#244C5A')};
  ${props => props.theme.typography.smallRegular as any}
  color: white;
`;
