export enum PolicyRuleParameterKeys {
  MALICIOUS_THRESHOLD = 'maliciousThreshold',
}

export const PolicyRuleParametersLegend = {
  'Malicious score threshold exceeded (Android)': {
    parameter: PolicyRuleParameterKeys.MALICIOUS_THRESHOLD,
    initialValue: 0,
  },
};
