export interface PolicyAction {
  id: number;
  name: string;
  description: string;
  key: string;
  shouldShowModal?: boolean;
  emails?: string[];
}

export interface PolicyActionGroup {
  group: 'messaging' | 'suspension';
  groupName: string;
  actions: PolicyAction[];
}

export type PolicyRule = {
  id: string;
  parameters?: {
    maliciousThreshold?: number;
  };
  rule: RuleEntity;
  policyId: string;
  ruleID: string;
  createdAt: string;
  updatedAt: string;
};

export type RuleEntity = {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  cmsKey?: string;
  platform: 'ios' | 'android' | 'all';
  skuSetting: 'device-rules' | 'app-rules';
  ruleDefinition: {
    type: string;
    value: string;
    field: string;
    subtype: string;
    operator: string;
  };
};

export type PolicyEntity = {
  id: string;
  name: string;
  description: string;
  enabled: boolean;
  createdAt: string;
  updatedAt: string;
  isDeleted: boolean;
  numberOfDevices: number;
  orgId: string;
  outputFieldName: string;
  policyRules: PolicyRule[];
  policyActions: PolicyAction[];
  notificationEmails: string[];
  remediations: string[];
};

export function getMdmActions(): PolicyActionGroup[] {
  return [
    {
      group: 'messaging',
      groupName: 'Notify Admin',
      actions: [
        {
          id: 1,
          name: 'Email alert',
          description: '',
          key: 'NOTIFICATION',
          shouldShowModal: true,
        },
      ],
    },
  ];
}

export function getActions(): PolicyActionGroup[] {
  return [
    {
      group: 'messaging',
      groupName: 'Notify Admin',
      actions: [
        {
          id: 1,
          name: 'Email alert',
          description: '',
          key: 'NOTIFICATION',
          shouldShowModal: true,
        },
      ],
    },
    {
      group: 'messaging',
      groupName: 'Notify Device Owner',
      actions: [
        {
          id: 2,
          name: 'Send email',
          description: '',
          key: 'MAIL',
        },
        {
          id: 3,
          name: 'Send push notification',
          description: '',
          key: 'PUSH_NOTIFICATION',
        },
      ],
    },
    {
      group: 'suspension',
      groupName: 'Conditional Access',
      actions: [
        {
          id: 4,
          name: 'Suspend Device in Okta Verify',
          description: '',
          key: 'CLOUD',
        },
      ],
    },
  ];
}
