import { Routes, Route } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Tab, Tabs } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import Layout from '../Layout/Layout';
import { ManagedAppsInfoContext, ManagedAppsInfoHook } from './AppManagementInfoContext';
import ManagedAppsTable from './ManagedApps/ManagedAppsTable';
import ManagedAppsSearch from './AppManagementSearch';
import { AppManagementProfilePage } from './AppManagementProfilePage';
// eslint-disable-next-line import/extensions
import { AppManagementStatus, AppManagementProfileMode } from './enum/AppManagementKeyMaps.enum';
import { KwContainer } from '../../kw-ui-components/KwContainer';
import DeniedAppsTable from './DeniedApps/DeniedAppsTable';
import { BackgroundProgressBar } from '../BackgroundProgressBar/BackgroundProgressBar';
import TrustedAppsTable from './TrustedApps/TrustedAppsTable';
import { orgHasSetting } from '../Organization/org-utils';
import { useOrg } from '../Organization/org-provider';
// eslint-disable-next-line @typescript-eslint/no-unused-vars

export default function AppManagement() {
  const {
    platform,
    setPlatform,
    mode,
    setMode,
    app,
    setApp,
    searchHistory,
    setSearchHistory,
    appManagementStatus,
    setAppManagementStatus,
  } = ManagedAppsInfoHook();

  // hide the Managed Apps tab if MDM is enabled
  const { orgData } = useOrg();
  const isMdmMode = orgHasSetting(orgData, 'mdm-integration');

  const [tab, setTab] = useState<number>(0);

  useEffect(() => {
    if (appManagementStatus === AppManagementStatus.MANAGED && isMdmMode) {
      setAppManagementStatus(AppManagementStatus.TRUSTED);
    }
  }, [appManagementStatus, isMdmMode]);

  const handleTabChange = (_event: ChangeEvent<HTMLButtonElement>, newTab: number) => {
    if (isMdmMode) {
      setTab(newTab);
      if (newTab === 0) {
        setAppManagementStatus(AppManagementStatus.TRUSTED);
      }
      if (newTab === 1) {
        setAppManagementStatus(AppManagementStatus.DENIED);
      }
    } else {
      setTab(newTab);
      if (newTab === 0) {
        setAppManagementStatus(AppManagementStatus.MANAGED);
      }
      if (newTab === 1) {
        setAppManagementStatus(AppManagementStatus.TRUSTED);
      }
      if (newTab === 2) {
        setAppManagementStatus(AppManagementStatus.DENIED);
      }
    }
  };

  return (
    <ManagedAppsInfoContext.Provider
      value={{
        platform,
        setPlatform,
        mode,
        setMode,
        app,
        setApp,
        searchHistory,
        setSearchHistory,
        appManagementStatus,
        setAppManagementStatus,
      }}
    >
      <Routes>
        <Route element={<Layout />}>
          <Route
            path="/"
            element={
              <KwContainer id={`app-management-table-container-${appManagementStatus}`}>
                <BackgroundProgressBar />
                <StyledTabContainer>
                  <StyledTabs value={tab} onChange={handleTabChange} aria-label="Managed/Denied App Management tabs">
                    {!isMdmMode && <StyledTab label="Managed Apps" />}
                    <StyledTab label="Trusted Apps" />
                    <StyledTab label="Denied Apps" />
                  </StyledTabs>
                </StyledTabContainer>
                {(() => {
                  switch (appManagementStatus) {
                    case AppManagementStatus.MANAGED:
                      return <ManagedAppsTable />;
                    case AppManagementStatus.TRUSTED:
                      return <TrustedAppsTable />;
                    case AppManagementStatus.DENIED:
                      return <DeniedAppsTable />;
                    default:
                      return null;
                  }
                })()}
              </KwContainer>
            }
          />
          <Route path="/search" element={<ManagedAppsSearch />} />
          <Route
            path="/add"
            element={<AppManagementProfilePage mode={AppManagementProfileMode.ADD} status={appManagementStatus} setTab={setTab} />}
          />
          <Route
            path="/edit/:managedAppId"
            element={<AppManagementProfilePage mode={AppManagementProfileMode.EDIT} status={appManagementStatus} setTab={setTab} />}
          />
        </Route>
      </Routes>
    </ManagedAppsInfoContext.Provider>
  );
}

const StyledTabContainer = styled(Box)`
  line-height: 1px;
  border-bottom: 1px solid ${props => props.theme.palette.greyOverride[300]};
`;

const StyledTab = styled(Tab)`
  text-transform: capitalize;
  padding: 20px 40px;
  &.Mui-selected {
    color: #000;
    font-weight: 700;
  }
`;

const StyledTabs = styled(Tabs)`
  & span.MuiTabs-indicator {
    background-color: ${props => props.theme.palette.accent.primary};
  }
`;
